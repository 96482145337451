import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import clamp from './clamp';
import styles from './dotdotdot.scss';

type Props = {
  clamp: 'auto' | 'parent' | number;
  className?: string;
  useExactLineHeight?: boolean;
  maxLineCount?: number;
  as?: keyof JSX.IntrinsicElements;
};

const DotDotDot: React.FC<Props> = (props) => {
  const Wrapper = (props.as ?? 'span') as 'span';
  const rootRef = React.useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (!props.clamp || !rootRef.current) {
      return;
    }

    if (React.Children.count(props.children) > 1) {
      throw new Error('Please provide exacly one child to dotdotdot');
    }

    clamp(rootRef.current, {
      clamp: props.clamp,
      useExactLineHeight: props.useExactLineHeight,
      useNativeClamp: true,
      truncationChar: '\u2026',
    });
  }, [props]);

  return (
    <Wrapper ref={rootRef} className={classNames(styles.root, props.className)}>
      {props.children}
    </Wrapper>
  );
};

// `withResponsiveContext` is used to force re-rendering of the component when root width changes.
export default withResponsiveContext(DotDotDot) as any as React.FC<Props>;
