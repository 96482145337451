import React from 'react';
import { useSelector } from '../components/runtime-context';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';

function forMembersArea<T extends React.PropsWithChildren<any>>(
  ComponentMembersArea: React.FC<T>,
  ComponentNoMembersArea: React.FC<T>,
) {
  const InnerComponent: React.FC<T> = (props) => {
    const isMemberAreaActive = useSelector(getIsMemberAreaInstalled);
    const Component = isMemberAreaActive
      ? ComponentMembersArea
      : ComponentNoMembersArea;
    return <Component {...props} />;
  };

  return InnerComponent;
}

export default forMembersArea;
